var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    ref: "refreshCard",
    attrs: {
      "action-collapse": "",
      "title": "Daftar Sales",
      "action-refresh": ""
    },
    on: {
      "refresh": function ($event) {
        return _vm.refreshStop('refreshCard');
      }
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "size": "xl",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "title": "Form Tambah"
    }
  }, [_c('validation-observer', {
    ref: "simpleRules"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-0 ml-50"
  }, [_vm._v("Data Personal")])], 1), _c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "v-nama-lengkap"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama-lengkap",
            "placeholder": "Nama Lengkap"
          },
          model: {
            value: _vm.nama,
            callback: function ($$v) {
              _vm.nama = $$v;
            },
            expression: "nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kelamin",
      "label-for": "v-jk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jk",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optjk
          },
          model: {
            value: _vm.jk,
            callback: function ($$v) {
              _vm.jk = $$v;
            },
            expression: "jk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor HP",
      "label-for": "v-nomor-hp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "no_hp",
      "rules": "required|min:9"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nomor-hp",
            "placeholder": "Nomor HP",
            "type": "number"
          },
          model: {
            value: _vm.no_hp,
            callback: function ($$v) {
              _vm.no_hp = $$v;
            },
            expression: "no_hp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "v-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email_kar",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-email",
            "type": "email",
            "placeholder": "Email"
          },
          model: {
            value: _vm.email_kar,
            callback: function ($$v) {
              _vm.email_kar = $$v;
            },
            expression: "email_kar"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIK",
      "label-for": "v-nik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nik",
      "rules": "required|min:2"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nik",
            "placeholder": "NIK",
            "type": "number"
          },
          model: {
            value: _vm.nik,
            callback: function ($$v) {
              _vm.nik = $$v;
            },
            expression: "nik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "alamat",
      "rules": "required|min:2"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Alamat Lengkap"
          },
          model: {
            value: _vm.alamat,
            callback: function ($$v) {
              _vm.alamat = $$v;
            },
            expression: "alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-0 ml-50"
  }, [_vm._v(" Gaji ")])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gaji Pokok",
      "label-for": "v-gaji-pokok"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Gaji Pokok",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-gaji-pokok",
            "placeholder": "Gaji Pokok",
            "type": "number"
          },
          model: {
            value: _vm.gapok,
            callback: function ($$v) {
              _vm.gapok = $$v;
            },
            expression: "gapok"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tunjangan",
      "label-for": "v-tunjangan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tunjangan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tunjangan",
            "placeholder": "Tunjangan",
            "type": "number"
          },
          model: {
            value: _vm.tunjangan,
            callback: function ($$v) {
              _vm.tunjangan = $$v;
            },
            expression: "tunjangan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bonus",
      "label-for": "v-bonus"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "bonus",
      "rules": "required|min:9"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-bonus",
            "placeholder": "Bonus",
            "type": "number"
          },
          model: {
            value: _vm.bonus,
            callback: function ($$v) {
              _vm.bonus = $$v;
            },
            expression: "bonus"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_vm._v(" Tambah ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(avatar)",
      fn: function (data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.value
          }
        })];
      }
    }, {
      key: "cell(status)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.status[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.status[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Detail',
            expression: "'Detail'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-secondary"
          },
          on: {
            "click": function ($event) {
              return _vm.info(row.item, row.index, $event.target);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.info(row.item, row.index, $event.target);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.info(row.item, row.index, $event.target);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.detailmodal.id,
      "title": _vm.detailmodal.title,
      "ok-only": ""
    },
    on: {
      "hide": _vm.resetdetailmodal
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.detailmodal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }